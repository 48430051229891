import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import { fas } from '@fortawesome/free-solid-svg-icons'

import store from './store/index';

import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import { fas } from '@fortawesome/free-solid-svg-icons'
import JsonCSV from 'vue-json-csv'





/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'



/* add icons to the library */
library.add(faUserSecret)







import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


// import {VueDataset} from 'vue-dataset'
// import VueDataTable from "@uwlajs/vue-data-table";



// aws_exports.oauth.redirectSignIn = 'https://www.google.com';



library.add(faUserSecret)
library.add( far, fab)
library.add(faPhone);
library.add(fas);






createApp(App)
    .component('downloadCsv', JsonCSV)
    .use(ElementPlus)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(router)
    .use(store)
    .use( LottieAnimation )
    .mount('#app')
