import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";


const allowedRoutes = ['notifications', 'PayOutView', 'ReportView', 'DonationHistory', 'PayOutHistory', 'HomeView', 'CauseView','UserCreationView'];


const routes = [
  {
    path: '/login',
    name: 'LoginView',
    // redirect: '/dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    // beforeEnter: (to, from, next) => {
    //   if (to.path === '/login' && localStorage.getItem('isAuthenticated')) {
    //     next('/');
    //   } else {
    //     next();
    //   }
    // },

  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    // redirect: '/dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword.vue'),

  },
  {
    path: '/',
    name: 'HomeView',


    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    meta: { requiresAuth: true, showHeaderAndSidebar: true }

  },
  {
    path: '/pay-out',
    name: 'PayOutView',

    component: () => import(/* webpackChunkName: "about" */ '../views/PayOutView.vue'),
    meta: { requiresAuth: true, showHeaderAndSidebar: true }
  },

  {
    path: '/pay-out-history',
    name: 'PayOutHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/PayoutHistory.vue'),
    // meta: {requiresAuth: true}
  },
  {
    path: '/history',
    name: 'DonationHistory',
      component: () => import(/* webpackChunkName: "about" */ '../views/DonationHistory.vue'),
    meta: { requiresAuth: true, showHeaderAndSidebar: true }
  },

  {
    path: '/:catchAll(.*)',
    name: 'NotFoundView',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFoundView.vue'),
    meta: { requiresAuth: true, showHeaderAndSidebar: true }
  },
  {
    path: '/errorpage',
    name: 'ErrorPageView',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFoundView.vue'),
    meta: { requiresAuth: true, showHeaderAndSidebar: true }
  },
    {
    path: '/cause',
    name: 'CauseView',
    component: () => import(/* webpackChunkName: "about" */ '../views/CauseView.vue'),
      meta: { requiresAuth: true, showHeaderAndSidebar: true }
    }
  ,
  {
    path: '/user-creation',
    name: 'UserCreationView',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserCreationView.vue'),
    meta: { requiresAuth: true, showHeaderAndSidebar: true }
  }

]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const showHeaderAndSidebar = allowedRoutes.includes(to.name);

  // Check if the route requires authentication and the user is not logged in
  if (requiresAuth && !store.state.sessionId) {
    // Redirect to the login page
    next('/login');
  } else {
    // Set the `showHeaderAndSidebar` value as a meta field on the route
    to.meta.showHeaderAndSidebar = showHeaderAndSidebar;
    // Continue navigation to the next route
    next();
  }
});





export default router
