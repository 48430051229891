<script setup>

import {ref, onMounted, onUnmounted, computed, watch} from 'vue';


import store from "@/store";
// Get the headersData directly from the Vuex store state
// const headersData = store.state.profileDetails;
//




const userName = computed(() => store.state.profileDetails.firstName);

const userDepartment = ref('Kada Ops');

const fetchUserDetails = async () => {
  try {
    await store.dispatch('fetchProfileDetails');
  } catch (error) {
    console.error('Error in component:', error);
    // Handle the error in the component if needed
  }
};

// Watch for changes in userName
watch(userName, (newUserName) => {
  if (!newUserName) {
    // If userName is empty, dispatch the action to fetch details
    fetchUserDetails();
  }
});

// Use onMounted to fetch data when the component is initially mounted
onMounted(() => {
  // Check if the userName is empty on initial mount
  if (!userName.value) {
    // If userName is empty, dispatch the action to fetch details
     fetchUserDetails();
  }
});




// Function to format the date as "Wed, 21 Jun 2023"
function formatDate(dateString) {
  const dateObject = new Date(dateString);
  const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
  const formattedString = dateObject.toLocaleDateString('en-US', options);

  // Remove all commas from the formattedString
  const format =  formattedString.replace(/,/g, '');

  // make it add comma only to the first word

  return format.replace(/^(\w+)/, '$1,');


}

// Reactive variable to store the formatted date
const formattedDate = ref('');

// Function to update the formatted date every second
function updateClock() {
  const now = new Date();
  formattedDate.value = formatDate(now);
}

let interval;
// Start the clock when the component is mounted
onMounted(() => {



  updateClock();
  // Update the clock every second
  interval = setInterval(updateClock, 1000);
});

// Stop the clock when the component is unmounted

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<template>

  <div class="header-container"  >
<!--    <div >-->
      <div class="header-date">    {{ formattedDate }}
      </div>
      <div class="header-info">
        <div class="sect__container">

          <div class="userName__format">

            {{ userName }}
          </div>
          <div class="dept__format">
            {{ userDepartment }}
          </div>
        </div>
        <img src="https://images.unsplash.com/photo-1573162915851-0af01102b7be?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1888&q=80" alt="Profile Image" class="header-image" />

      </div>
    </div>
<!--  </div>-->
</template>

<style scoped lang="scss">


/* Basic styling for the header container */
.header-container {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 90px;
  background-color: #FFFFFF;
  height: 65px;
  //border-bottom: 1px solid #ccc;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 100px;

}

/* Styling for the date on the left */
.header-date {
  //flex: 1;
  //
  //height: 20px;
  //width: 130px;
  color: #07182D;
  font-family: "Euclid Circular B";
  font-size: 16px;
  font-weight: 550;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
}

/* Styling for the image */
.header-image {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
  x-sizing: border-box;
  height: 48px;
  width: 48px;
  border: 2px solid rgba(23,79,196,0.52);
  border-radius: 24px;
}

.header-info {
  display: flex;
  flex-direction: row;
}

.sect__container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 15px;
  padding-top: 7px;
}

.userName__format{

  color: #07182D;
  font-family: "Euclid Circular B";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
}


.dept__format{

  color: #9E9E9E;
  font-family: "Euclid Circular B";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 13px;
  padding-top: 4px;

}

</style>