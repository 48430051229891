<script setup>
import { useRoute } from 'vue-router';
const route = useRoute();
const isActiveRoute = (path) => route.path === path || route.fullPath === path;
// const activeRoute = ref('');


// import { ref } from 'vue';
import router from "@/router";
import store from "@/store";

// const signedIn = ref(true);

// Async function to handle sign-out
const signOut =  () => {
  store.dispatch("logout")


};

const homePage = () => {

  router.push('/');

};


</script>

<template>

  <div class="area"></div><nav class="main-menu">


    <ul>



      <div class="logo-container" @click="homePage">
        <div class="logo2__format">
          <img src="https://res.cloudinary.com/dan4b75j7/image/upload/v1694534205/kadaLogo_xowxmm.png" alt="Logo 1" class="logo logo1">

        </div>
        <div class="logo1__format">
          <img src="https://res.cloudinary.com/dan4b75j7/image/upload/v1696372414/kadacentral/mleyk0jztlvleros7qqt.png" alt="Logo 2" class="logo logo2">

        </div>

        <!--        <img :src="logo2.png" alt="Logo 2" class="logo logo2">-->
      </div>

      <div class="menu">
        MAIN MENU
      </div>






<!--      <li class="has-subnav">-->
<!--        <a href="#" class="img__format">-->
<!--&lt;!&ndash;          <i class="fa  fa-building-o fa-2x"></i>&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="fas fa-layer-group" style="color: #042e83;"></i>&ndash;&gt;-->
<!--          <font-awesome-icon :icon="['fas', 'layer-group']" class="fa icon-white" size="lg" />-->

<!--&lt;!&ndash;          <font-awesome-icon class="fa fa-2x" :icon="['fas', 'layer-group']" style="color: #042e83;" />&ndash;&gt;-->

<!--&lt;!&ndash;          <font-awesome-icon class="fa" :icon="['fas', 'layer-group']" style="color: #042e83;" />&ndash;&gt;-->

<!--          <span class="nav-text">-->
<!--                             Dashboard-->
<!--                        </span>-->
<!--        </a>-->

<!--      </li>-->

      <li class="has-subnav" :class="{ 'active': isActiveRoute('/') }">
        <router-link to="/"
                     :class="{ 'active': isActiveRoute('/')}"
                     class="img__format">
          <!--          <i class="fa  fa-building-o fa-2x"></i>-->
          <!--          <i class="fas fa-layer-group" style="color: #042e83;"></i>-->
          <div class="groupImg">
            <font-awesome-icon     :class="{ 'active': isActiveRoute('/') }" :icon="['fas', 'layer-group'] " class="icon-white" size="lg" />
          </div>
          <!--          <font-awesome-icon class="fa fa-2x" :icon="['fas', 'layer-group']" style="color: #042e83;" />-->

          <!--          <font-awesome-icon class="fa" :icon="['fas', 'layer-group']" style="color: #042e83;" />-->

          <span class="nav-text">
                          Dashboard
                        </span>
        </router-link>

      </li>

      <li class="has-subnav"  :class="{ 'active': isActiveRoute('/pay-out') }">
        <router-link
            :class="{ 'active': isActiveRoute('/pay-out') }"
            to="/pay-out" href="#" class="img__format">
          <!--          <i class="fa  fa-building-o fa-2x"></i>-->
          <!--          <i class="fas fa-layer-group" style="color: #042e83;"></i>-->
<!--          <font-awesome-icon :icon="['fas', 'layer-group']" class="fa icon-white" size="lg" />-->

          <div class="groupImg">
            <font-awesome-icon     :class="{ 'active': isActiveRoute('/pay-out') }"     :icon="['fas', 'coins']" class="icon-white" size="lg" />
            <font-awesome-icon :icon="['fas', 'circle']" size="2xs" style="color: #ff6060;" class="redObj"/>

          </div>


          <!--          <font-awesome-icon class="fa fa-2x" :icon="['fas', 'layer-group']" style="color: #042e83;" />-->

          <!--          <font-awesome-icon class="fa" :icon="['fas', 'layer-group']" style="color: #042e83;" />-->

          <span class="nav-text">
                           Payout Request
                        </span>
        </router-link>

      </li>



      <li class="has-subnav" :class="{ 'active': isActiveRoute('/history') }">
        <router-link to="/history"
                     :class="{ 'active': isActiveRoute('/history')}"
                      class="img__format">
          <!--          <i class="fa  fa-building-o fa-2x"></i>-->
          <!--          <i class="fas fa-layer-group" style="color: #042e83;"></i>-->
          <div class="groupImg">
          <font-awesome-icon     :class="{ 'active': isActiveRoute('/history') }" :icon="['fas', 'cash-register']" class="icon-white" size="lg" />
          </div>
          <!--          <font-awesome-icon class="fa fa-2x" :icon="['fas', 'layer-group']" style="color: #042e83;" />-->

          <!--          <font-awesome-icon class="fa" :icon="['fas', 'layer-group']" style="color: #042e83;" />-->

          <span class="nav-text">
                           Donation History
                        </span>
        </router-link>

      </li>

<!--      <li class="has-subnav"      :class="{ 'active': isActiveRoute('/notifications') }">-->
<!--        <router-link to="/notifications" class="img__format">-->
<!--          &lt;!&ndash;          <i class="fa  fa-building-o fa-2x"></i>&ndash;&gt;-->
<!--          &lt;!&ndash;          <i class="fas fa-layer-group" style="color: #042e83;"></i>&ndash;&gt;-->
<!--          <font-awesome-icon  :class="{ 'active': isActiveRoute('/notifications') }" :icon="['fas', 'bell']" class="fa icon-white" size="lg" />-->

<!--          &lt;!&ndash;          <font-awesome-icon class="fa fa-2x" :icon="['fas', 'layer-group']" style="color: #042e83;" />&ndash;&gt;-->

<!--          &lt;!&ndash;          <font-awesome-icon class="fa" :icon="['fas', 'layer-group']" style="color: #042e83;" />&ndash;&gt;-->

<!--          <span class="nav-text">-->
<!--                           Notification-->
<!--                        </span>-->
<!--        </router-link>-->

<!--      </li>-->



      <li class="has-subnav" :class="{ 'active': isActiveRoute('/pay-out-history') }">
        <router-link to="/pay-out-history"  href="#" class="img__format">
          <!--          <i class="fa  fa-building-o fa-2x"></i>-->
          <!--          <i class="fas fa-layer-group" style="color: #042e83;"></i>-->
          <div class="groupImg">
          <font-awesome-icon :class="{ 'active': isActiveRoute('/pay-out-history') }" :icon="['fas', 'file-alt' +
           '']" class="icon-white" size="lg" />
          </div>
          <!--          <font-awesome-icon class="fa fa-2x" :icon="['fas', 'layer-group']" style="color: #042e83;" />-->

          <!--          <font-awesome-icon class="fa" :icon="['fas', 'layer-group']" style="color: #042e83;" />-->

          <span class="nav-text">
                            Payout History
                        </span>
        </router-link>

      </li>

      <li class="has-subnav" :class="{ 'active': isActiveRoute('/cause') }">
        <router-link to="/cause"  href="#" class="img__format">
          <!--          <i class="fa  fa-building-o fa-2x"></i>-->
          <!--          <i class="fas fa-layer-group" style="color: #042e83;"></i>-->
          <div class="groupImg">
            <font-awesome-icon :class="{ 'active': isActiveRoute('/cause') }" :icon="['fas', 'ribbon' +
           '']" class="icon-white" size="lg" />
          </div>
          <!--          <font-awesome-icon class="fa fa-2x" :icon="['fas', 'layer-group']" style="color: #042e83;" />-->

          <!--          <font-awesome-icon class="fa" :icon="['fas', 'layer-group']" style="color: #042e83;" />-->

          <span class="nav-text">
                           Create Cause
                        </span>
        </router-link>

      </li>

    </ul>



    <ul class="logout">



      <div class="menu">
        Account
      </div>
      <li class="has-subnav" :class="{ 'active': isActiveRoute('/user-creation') }">
        <router-link to="/user-creation"  href="#" class="img__format">
          <div class="groupImg">

            <font-awesome-icon
                :class="{ 'active': isActiveRoute('/user-creation') }"
                size="lg"
                :icon="['fas', 'user-plus']"
                class="redObj"

            />

          </div>
          <span class="nav-text">
                             Account Creation
                        </span>
        </router-link>

      </li>
      <li class="has-subnav"  @click="signOut">

        <a href="#" class="img__format">
<!--          <i class="fa fa-power-off fa-2x"></i>-->
          <div class="groupImg">
          <font-awesome-icon :icon="['fas', 'sign-out-alt']" class="icon-white" size="lg" />
          </div>
          <span class="nav-text">
                            Logout
                        </span>
        </a>
      </li>
    </ul>
  </nav>


</template>

<style scoped lang="scss">



@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);



@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);





.main-menu {
  background: #043883;
  border-right: 1px solid #e5e5e5;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 70px;
  //overflow: hidden;
  //transition: width 0.05s linear;
  //-webkit-transform: translateZ(0) scale(1, 1);

  //margin-left: 70px;
  transition: border 0.3s ease-in-out; /* Add a transition for the border */
}

.main-menu>ul {
  margin:7px 0;

}




.main-menu li {
  position: relative;
  display: block;
  width: auto;
  height: 60px;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-menu li>a {
  position:relative;
  display:table;
  border-collapse:collapse;
  border-spacing:0;
  //color:#999;
  color: #EBF4FF;

  font-family: arial;
  font-size: 14px;
  text-decoration:none;
  //color: #EBF4FF;
  font-family: "Euclid Circular B";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  //-webkit-transform:translateZ(0) scale(1,1);
  //-webkit-transition:all .1s linear;
  //transition:all .1s linear;

}

.main-menu .nav-icon {
  position:relative;
  display:table-cell;
  width:60px;
  height:36px;
  text-align:center;
  vertical-align:middle;
  font-size:18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  //width: 190px;
  text-align: left;
  font-family: "Euclid Circular B";

  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  padding-left: 10px;


}


/* Your existing styles */
.main-menu .nav-text {
  /* ... your existing styles ... */
  visibility: hidden;
  transition: border 0.3s ease-in-out; /* Add a transition for the border */
}

/* Show the text on hover (only affects ul, not li) */
.main-menu:hover .nav-text {
  visibility: visible;

}

/* Show the li text on hover (optional) */
.main-menu li:hover .nav-text {
  visibility: visible;

}


.main-menu>ul.logout {
  position: fixed;
  bottom: 0;
  padding-bottom: 10px;
  //bottom:0;
  //padding-top: 11px;
}

.no-touch .scrollable.hover {
  overflow-y:hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y:auto;
  overflow:visible;
}

a:hover,a:focus {
  text-decoration:none;
}

nav {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  -o-user-select:none;
  user-select:none;
}

nav ul,nav li {
  outline:0;
  margin:0;
  padding:0;


}
.main-menu li:hover>a,nav.main-menu li.active>a,.dropdown-menu>li>a:hover,.dropdown-menu>li>a:focus,.dropdown-menu>.active>a,.dropdown-menu>.active>a:hover,.dropdown-menu>.active>a:focus,.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,.dashboard-page nav.dashboard-menu ul li.active a {
  color: #043883;
  background-color: #fff;
  background: linear-gradient(to right, #043883 10%, #fff 10%);
  display: flex;
  flex-direction: row;
  height: 52px;
  height: 48px;
  width: 238px;
  border-radius: 5px 0 0 5px;
  background-color: #FFFFFF;
  align-items: center;
  background: linear-gradient(to right, #043883 10%, #F0F4F7 10%);
  margin-left: 5px;

  //
  //.logo2{
  //  display: block;
  //}


  //svg.svg-inline--fa.fa-coins.fa-w-16.fa-lg.active.icon-white {
  //  color: #042e83;
  //  padding-left: 17px;
  //}

}



.main-menu li:hover{
  //display: flex;

}

.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: local('Titillium WebLight'), local('TitilliumWeb-Light'), url(http://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff) format('woff');
}

/* Your existing styles for .menu */
.menu {
  width: 229px;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Euclid Circular B";
  font-size: 14px;
  text-align: start;
  font-weight: 500;
  text-indent: 30px;
  padding-bottom: 15px;
  display: none; /* Hide the .menu element by default */
}

/* Show .menu when .main-menu is hovered on */
.main-menu:hover .menu {
  display: block;
}
/* Your existing styles for .logo-container */
.logo-container {
  position: relative;
  cursor: grab;
}


/* Hide the first logo by default */
//.logo2 {
//  //display: none;
//  width: 100%;
//  padding-bottom: 50px;
//}


/* Show .logo1 when .main-menu is not being hovered */
//.main-menu:not(:hover) .logo2 {
//  display: block;
//}

/* Show .logo2 when .main-menu is hovered on */


/* Hide .logo2 when .main-menu is not being hovered */


/* Assuming you have set the font size for the icon */
.icon-white {
  color: white; /* Set the default color to white */
  transition: color 0.2s ease-in-out; /* Optional transition effect */
}




.main-menu li:hover .icon-white {
  color: #042e83;
  margin-left: 15px;
  //padding-right: 10px;
}

.main-menu:active li:active .icon-white:active {
  //color: #042e83;
  margin-left: 15px;
  //padding-right: 10px;
}

.main-menu li:hover .logo-container .logo2 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 25px;
  margin-left: 35px;
}

.groupImg {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-content: flex-start;
  margin-left: 30px;
}

svg.svg-inline--fa.fa-circle.fa-w-16.fa-2xs.redObj {
  width: 8px;
  margin-top: -7fpx;
  margin-left: 0px;
}

.menu-item.active {
  background-color: pink; /* Set the background color to pink for the active route */
}

/* Your existing styles */

/* ... */

/* Show the li text on hover (optional) */
.main-menu li:hover .nav-text{
  visibility: visible;
  width: 0;
  width: 100%;
}

/* Add styles for the "active" class */
.main-menu li.active {
  //background-color: pink;
  padding-left: -400px;
}

/* Change color of .icon-white to red when .menu-item has .active class */
.menu-item.active .icon-white{
  color: red;
}

svg.fa.icon-white.active{
  //color: #83045f;
  margin-left: 7px;

}


svg.fa.icon-white{
  //color: #042e83;
  margin-left: 2px;

}

a.active.img__format {
  width: 15px;
}

nav.main-menu li.active {
  width: 100%;
}




 //.main-menu li:hover .icon-white {
 //  color: #042e83;
 //  margin-left: 15px;
 //}

//.main-menu li  .icon-white {
//  color: #042e83;
//  margin-left: 0px;
//}

svg.svg-inline--fa.fa-coins.fa-w-16.fa-lg.active.icon-white {
  color: #042e83;
}

svg.svg-inline--fa.fa-cash-register.fa-w-16.fa-lg.active.icon-white {
  color: #042e83;
}

svg.svg-inline--fa.fa-file-alt.fa-w-12.fa-lg.active.icon-white {
  color: #042e83;
}


svg.svg-inline--fa.fa-layer-group.fa-w-16.fa-lg.active.icon-white {
  color: #042e83;
}

svg.svg-inline--fa.fa-ribbon.fa-w-14.fa-lg.active.icon-white {
  color: #042e83;
}

img.logo.logo2 {
  height: 70px;
  width: 60px;
  -o-object-fit: contain;
  object-fit: unset;
}

.logo1__format {
  padding-bottom: 50px;
  padding-top: 35px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.main-menu:hover,nav.main-menu.main-menu>ul:hover {
  width: 230px;
  z-index: 12;

  overflow:visible;
  cursor: grab;



}

img.logo.logo1 {
  height: 50px;
  width: 121px;
  -o-object-fit: unset;
  object-fit: unset;
}

.logo2__format {
  height: 50px;
  width: 121px;
  -o-object-fit: unset;
  object-fit: unset;
}

.logo2__format {
  display: none;
}

nav:hover .logo1__format,
ul:hover .logo1__format {
  display: none;
}

nav.main-menu:hover .logo2__format {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 28px;
  padding-top: 43px;
  padding-bottom: 80px;
}


//.groupImg {
//  display: flex;
//  flex-direction: row;
//  align-items: stretch;
//  align-content: flex-start;
//  margin-left: 41px;
//}

</style>