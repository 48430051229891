import { createStore } from 'vuex'
import axios from 'axios'
import router from '@/router'
import { ElMessage } from 'element-plus'

const store = createStore({
  state: {
    showModalDetailsBelow: false,

    sessionId: sessionStorage.getItem('sessionId') || null,

    deviceData: {
      deviceId: '',
      deviceName: '',
      deviceOS: '',
      osVersion: '',
    },

    filterBY: {
      payoutStatus: null,
      requestType: null,
    },
    causePhotoResponse: null,

    causeTypeBtn: [
      {
        name: 'Schools',
        value: 'All Schools',
        index: 0,
      },
      {
        name: 'Tertiary',
        value: 'Universities Only',
        index: 0,
      },
      {
        name: 'Secondary',
        value: 'Secondary Schools Only',
        index: 0,
      },
      {
        name: 'Primary',
        value: 'Primary Schools Only',
        index: 0,
      },
      {
        name: 'Others',
        value: 'Other Schools Only',
        index: 0,
      },
    ],

    isSuccessCauseCreation: false,
    formData: {
      NameOfCause: '',
      description: '',
      targetAmount: '',
      deadline: null,
      minimumContribution: '',
      selectedSchoolType: '',
    },
    userFormData: {
      firstName: null,
      lastName: null,
      userEmail: null,
      phoneNumber: null,
      password: null,
    },
    causeListData: null,
    dashboardData: null,
    kadaCentralUsers: null,
    start_date: null,
    end_date: null,
    dataFetchLength: 15,

    modalOpenCreateCause: false,
    modalDetailsToggle: false,
    isSuccessModalUnblockShow: false,
    ErrorMessageresponse: '',
    detailsErrorModal: false,
    currentOfflineId: '',
    currentPayStackId: '',
    showPaymentMethodAvailable: false,

    isLoadingDetails: false,
    isLoading: true,
    ShowErrorDeclineReason: false,
    showErrorOneTimePayment: false,
    showErrorOfflineOTP: false,
    holdTransactionAlertModal: false,
    reasonNotToApprove: '',
    showDeclineApproval: false,
    offlinePayment: false,
    isPaystackPaymentModalOpen: false,
    isSuccessModalOpen: false,
    selectedDonation: '',
    isModalOpen: false,
    offlineOtpValue: '',
    // isBlurActive: false,
    paystackInitializeData: '',
    oneTimePaymentInitializeData: '',

    otpOneTime: '',
    isBlurActive: false,
    payoutHistoryAPI: null,

    payoutDetailsAPI: null,
    payoutConfig: '',
    donationHistory: null,

    totalRequestVal: '₦ 7,005,715.65',
    totalDonation: '₦ 182,154,735.60',
    totalPayout: '₦ 12,154,735.60',
    profileDetails: {},
  },
  mutations: {
    setShowModalDetailsBelow(state, payload) {
      state.showModalDetailsBelow = payload
    },

    clearSessionId(state) {
      state.sessionId = null
      sessionStorage.removeItem('sessionId') // Remove from sessionStorage
    },

    setSessionId(state, sessionId) {
      state.sessionId = sessionId
      sessionStorage.setItem('sessionId', sessionId) // Save to sessionStorage
    },

    setPayoutStatus(state, payload) {
      state.filterBY.payoutStatus = payload
    },

    setRequestType(state, payload) {
      state.filterBY.requestType = payload
    },

    setCauseListData(state, payload) {
      state.causeListData = payload
    },

    setDeviceData(state, payload) {
      state.deviceData = payload
    },
    setsaveFormData(state, payload) {
      state.formData = payload
    },

    setCausePhotoResponse(state, data) {
      state.causePhotoResponse = data
    },

    setCauseTypeBtn(state, data) {
      state.causeTypeBtn = data
    },

    setIsSuccessCauseCreation(state, payload) {
      state.isSuccessCauseCreation = payload
    },

    setFormData(state, payload) {
      state.formData = payload
    },
    setUserFormData(state, payload) {
      state.userFormData = payload
    },

    updateDashboardData(state, dashboardData) {
      // Update the state with the received dashboard data
      state.dashboardData = dashboardData
    },
    setKadaCentralUsers(state, payload) {
      // Update the state with the received dashboard data
      state.kadaCentralUsers = payload
    },
    incrementDataFetchLength(state, payload) {
      state.dataFetchLength += payload
    },
    setDataFetchLength(state, payload) {
      state.dataFetchLength = payload
    },
    setStartDate(state, newStartDate) {
      state.start_date = newStartDate
    },
    setEndDate(state, newEndDate) {
      state.end_date = newEndDate
    },

    SetPayoutHistoryAPI(state, payload) {
      state.payoutHistoryAPI = payload
    },
    setModalDetailsToggle(state, payload) {
      state.modalDetailsToggle = payload
    },
    setModalOpenCreateCause(state, payload) {
      state.modalOpenCreateCause = payload
    },
    setModalClose(state, payload) {
      state.modalPayoutRequest = payload
    },
    setSuccessModalUnblockShow(state, payload) {
      state.isSuccessModalUnblockShow = payload
    },
    setDetailsErrorModal(state, payload) {
      state.detailsErrorModal = payload
    },
    setErrorMessageresponse(state, payload) {
      state.ErrorMessageresponse = payload
    },
    setShowPaymentMethodAvailable(state, payload) {
      state.showPaymentMethodAvailable = payload
    },
    setCurrentOfflineId(state, payload) {
      state.currentOfflineId = payload
    },

    setCurrentPayStackId(state, payload) {
      state.currentPayStackId = payload
    },

    setIsLoadingDetails(state, payload) {
      state.isLoadingDetails = payload
    },
    setOneTimePaymentInitializeData(state, payload) {
      state.oneTimePaymentInitializeData = payload
    },
    setPaystackInitializeData(state, payload) {
      state.paystackInitializeData = payload
    },
    setPayoutConfig(state, payload) {
      state.payoutConfig = payload
    },
    setIsLoading(state, payload) {
      state.isLoading = payload
    },

    setIsBlurActive(state, payload) {
      state.isBlurActive = payload
    },
    setPayoutDetailsAPITest(state, payload) {
      state.payoutDetailsAPITest = payload
    },
    setDonationHistory(state, payload) {
      state.donationHistory = payload
    },
    setPayoutDetailsAPI(state, payload) {
      state.payoutDetailsAPI = payload
    },
    setShowErrorDeclineReason(state, payload) {
      state.ShowErrorDeclineReason = payload
    },

    setShowErrorOfflineOTP(state, payload) {
      state.showErrorOfflineOTP = payload
    },

    setShowErrorOneTimePayment(state, payload) {
      state.showErrorOneTimePayment = payload
    },
    setHoldTransactionAlertModal(state, payload) {
      state.holdTransactionAlertModal = payload
    },

    setInputDeclineText(state, payload) {
      state.reasonNotToApprove = payload
    },
    setShowDeclineApproval(state, payload) {
      state.showDeclineApproval = payload
    },
    setOfflineOtpValue(state, payload) {
      state.offlineOtpValue = payload
    },

    setOfflinePayment(state, payload) {
      state.offlinePayment = payload
    },
    setSuccessModalShow(state, payload) {
      state.isSuccessModalOpen = payload
    },
    setOneTimePaymentModal(state, payload) {
      state.isPaystackPaymentModalOpen = payload
    },
    setOtpOneTime(state, otp) {
      state.otpOneTime = otp
    },

    setSelectedDonation(state, donation) {
      state.selectedDonation = donation
    },

    setIsModalOpen(state, payload) {
      state.isModalOpen = payload
    },

    SET_PROFILE_DETAILS(state, payload) {
      state.profileDetails = { ...payload }
    },
  },
  actions: {
    // async handleErrorResponse({commit, dispatch}, errorResponse) {
    //
    //
    //
    //     if (errorResponse && errorResponse.response && (errorResponse.response.status === 401 || errorResponse.response.status === 403)) {
    //         ElMessage.error(errorResponse);
    //
    //
    //
    //         dispatch('logout');
    //     } else {
    //         const errorMessage = errorResponse.data?.error?.messages[0] || 'Request failed!';
    //         commit('setError', errorMessage);
    //         ElMessage.error(errorMessage);
    //         console.error('Request Error:', errorResponse);
    //         // Navigate to the error page
    //         await router.push({name: 'ErrorPageView'});
    //     }
    // },

    async handleErrorResponse({ commit, dispatch }, errorResponse) {
      if (
        errorResponse &&
        errorResponse.response &&
        (errorResponse.response.status === 401 ||
          errorResponse.response.status === 403)
      ) {
        // Unauthorized or forbidden status
        const errorMessage =
          errorResponse.data?.error?.messages[0] ||
          'Unauthorized access. Logging out...'
        ElMessage.error(errorMessage)

        // Optionally, show a message related to logout
        dispatch('logout')
      } else {
        // Extract error message from the Axios response
        const errorMessage =
          errorResponse.data?.error?.messages[0] || 'Request failed!'

        // Commit the error to the Vuex store
        commit('setError', errorMessage)

        // Display the API error message using Element UI
        ElMessage.error(errorMessage)

        // Log the error details
        console.error('Request Error:', errorResponse)

        // Navigate to the error page
        await router.push({ name: 'ErrorPageView' })
      }
    },
    logout({ commit }) {
      commit('clearSessionId')
      router.push('/login')
    },

    async fetchProfileDetails({ commit, state, dispatch }) {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API_KEY_KADA_SERVICE + '/identity/me',
          {
            headers: {
              Authorization: `Bearer ${state.sessionId}`,
            },
          },
        )

        const userDetails = response.data.responseContent
        commit('SET_PROFILE_DETAILS', userDetails)
        return userDetails
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          ElMessage.error(error.response.message)
          // Unauthorized or Forbidden status, indicating an issue with the Authorization header
          console.error('Unauthorized access. Logging out...')

          dispatch('logout') // You should have a logout action in your store to handle the logout process
        } else {
          console.error('Error fetching user details:', error)
          ElMessage.error(error.message)
        }
        throw error
      }
    },

    async getCauseListdata({ commit, state, dispatch }) {
      commit('setIsLoading', true)
      commit('setError', null)
      commit('setSuccess', null)

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_KEY_KADA_SERVICE + '/school/cause/admin/list',
          {
            cause_type: state.filterBY.requestType,
            status: state.filterBY.payoutStatus,
            start_date: null,
            end_date: null,
          },
          {
            params: {
              pageSize: 20,
              page: 1,
            },
            headers: {
              Authorization: `Bearer ${state.sessionId}`,
            },
          },
        )

        commit('setCauseListData', response.data.responseContent)
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          dispatch('logout') // You should have a logout action in your store to handle the logout process
        } else {
          ElMessage.error(error.response.message)
          // commit('setError', 'Request failed!');
          console.error('Request Error:', error)
          await router.push({ name: 'ErrorPageView' })
        }
      } finally {
        commit('setIsLoading', false)
      }
    },

    // get more data for cause list

    async getMoreCauseListData({ commit, state, dispatch }) {
      try {
        const newLimit = state.dataFetchLength + 20
        const apiUrl = await axios.post(
          process.env.VUE_APP_API_KEY_KADA_SERVICE + '/school/cause/admin/list',
          {
            cause_type: state.filterBY.requestType,
            status: state.filterBY.payoutStatus,
            start_date: state.start_date,
            end_date: state.end_date,
          },
          {
            params: {
              pageSize: newLimit,
              page: 1,
            },
            headers: {
              Authorization: `Bearer ${state.sessionId}`,
            },
          },
        )

        commit('setCauseListData', apiUrl.data.responseContent)
        commit('setDataFetchLength', newLimit)
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          // Unauthorized or Forbidden status, indicating an issue with the Authorization header
          console.error(
            'Unauthorized or Forbidden access. Logging out or redirecting to error page...',
          )

          dispatch('logout')
        } else {
          console.error('Request Error:', error)
          ElMessage.error(error.response.message)
          await router.push({ name: 'ErrorPageView' })
        }
      } finally {
        commit('setIsLoading', false)
      }
    },
    async registerKadaAdminUser({ state, commit, dispatch }) {
      try {
        commit('setIsLoadingDetails', true)

        const secondApiUrl =
          process.env.VUE_APP_API_KEY_KADA_SERVICE + '/admin/identity/signup'
        const secondApiPayload = {
          currency: 'NGN',
          deviceId: state.deviceData.deviceId,
          deviceName: state.deviceData.deviceName,
          deviceOS: state.deviceData.deviceOS,
          osVersion: state.deviceData.osVersion,
          emailAddress: state.userFormData.userEmail,
          mobileNumber: state.userFormData.phoneNumber,
          password: state.userFormData.password,
          confirmPassword: state.userFormData.password,
          firstName: state.userFormData.firstName,
          lastName: state.userFormData.lastName,
          promoteIfExists: true,
        }

        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        const secondApiResponse = await axios.post(
          secondApiUrl,
          secondApiPayload,
          { headers },
        )

        // Show success modal
        commit('setIsSuccessCauseCreation', true)

        // Handle the second API response
        console.log('Second API Response:', secondApiResponse.data)
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          // Unauthorized or forbidden status
          const errorMessage =
            error.response.data?.error?.messages[0] ||
            'Unauthorized access. Logging out...'
          ElMessage.error(errorMessage)
          dispatch('logout')
        } else {
          // Extract error message from the Axios response
          const errorMessage =
            error.response?.data?.error?.messages[0] || 'Request failed!'

          // Commit the error to the Vuex store
          commit('setErrorMessageresponse', errorMessage)

          // Display the API error message using Element UI
          ElMessage.error(errorMessage)
          // Handle any errors in the second API call
          commit('setIsLoadingDetails', false)
          commit('setDetailsErrorModal', true)
        }
      } finally {
        commit('setIsLoadingDetails', false)
      }
    },

    async submitFormData({ state, commit, dispatch }) {
      try {
        // commit('setIsLoadingDetails', true);

        const secondApiUrl =
          process.env.VUE_APP_API_KEY_KADA_SERVICE +
          '/school/cause/admin/creation'
        const secondApiPayload = {
          currency: 'NGN',
          deviceId: state.deviceData.deviceId,
          deviceName: state.deviceData.deviceName,
          deviceOS: state.deviceData.deviceOS,
          osVersion: state.deviceData.osVersion,
          causeName: state.formData.NameOfCause,
          causeDescription: state.formData.description,
          targetAmount: state.formData.targetAmount,
          deadline: state.formData.deadline,
          causeType: state.formData.selectedSchoolType,
          minimumContribution: state.formData.minimumContribution,
          courseImageIconId: state.causePhotoResponse.id,
        }

        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        const secondApiResponse = await axios.post(
          secondApiUrl,
          secondApiPayload,
          { headers },
        )

        // Show success modal
        commit('setIsSuccessCauseCreation', true)

        // Handle the second API response
        console.log('Second API Response:', secondApiResponse.data)
      } catch (error) {
        // Handle any errors in the second API call
        commit('setIsLoadingDetails', false)
        commit('setDetailsErrorModal', true)
        commit(
          'setErrorMessageresponse',
          error?.response?.data?.responseMessage ||
            'Error during form submission',
        )
        await dispatch('handleErrorResponse', error) // Dispatch error handling
      } finally {
        commit('setIsLoadingDetails', false)
      }
    },

    async causePhotoUpload({ commit, dispatch, state }, formData) {
      try {
        commit('setIsLoadingDetails', true)

        const apiUrl = process.env.VUE_APP_API_KEY_KADA_SERVICE
        const photoUploadEndpoint = '/identity/admin/fileupload'
        const completePhotoUploadUrl = apiUrl + photoUploadEndpoint
        const headers = {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        const response = await axios.post(completePhotoUploadUrl, formData, {
          headers,
        })

        if (response.status === 200) {
          commit('setCausePhotoResponse', response.data.responseContent)
          // Assuming this is another action you want to dispatch on successful photo upload
          await dispatch('submitFormData')
        } else {
          console.error(
            'Cause Photo Upload Error - Non-200 Status:',
            response.status,
          )
          // Dispatch error handling
          await dispatch('handleErrorResponse', response)
        }
      } catch (error) {
        commit('setIsLoadingDetails', false)
        commit('setDetailsErrorModal', true)
        commit(
          'setErrorMessageresponse',
          error?.response?.data?.message || 'Error during photo upload',
        )
        // Dispatch error handling
        await dispatch('handleErrorResponse', error)
      } finally {
        commit('setIsLoadingDetails', false)
      }
    },

    async getcauseTypeBtnAPI({ commit, state, dispatch }) {
      try {
        // Set loading indicator to true

        const apiUrl = process.env.VUE_APP_API_KEY_KADA_SERVICE
        const endpoint = '/identity/runtime-data/list'
        const requestBody = {
          dataSettings: ['CAUSETYPE_LIST'],
          dataSettingsParents: [],
          parentIds: [],
        }

        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        // Combine the base URL with the endpoint
        const completeUrl = apiUrl + endpoint

        // Use Axios for the POST request
        const response = await axios.post(completeUrl, requestBody, {
          headers,
        })

        // Handle the API response
        if (response.status === 200) {
          if (
            response.data &&
            response.data.responseContent &&
            response.data.responseContent.runtimeParams
          ) {
            const data = response.data
            commit(
              'setCauseTypeBtn',
              data.responseContent.runtimeParams[0].paramValues,
            )
          } else {
            // Handle the case where the response data structure is not as expected
            const errorMessage =
              'Invalid API response structure. Please check the server response.'
            ElMessage.error(errorMessage)
            console.error(errorMessage)
          }
        } else {
          // Handle other HTTP status codes (e.g., 404, 500) here if needed
          await dispatch('handleErrorResponse', response)
        }
      } catch (error) {
        // Handle any errors that occur during the API request
        await dispatch('handleErrorResponse', error.message)
      } finally {
        // Set loading indicator to false
      }
    },

    async holdTransactionApiCall({ commit, state, dispatch }) {
      try {
        commit('setIsLoadingDetails', true)

        const apiUrl = `${process.env.VUE_APP_API_KEY_KADA_SERVICE}/admin/transactions/payout/hold-unhold`
        const requestData = {
          action_type: 'Hold',
          transaction_id: state.selectedDonation.transaction_id,
          comment: 'Verifying if the request was valid',
        }

        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        // Make the POST request using axios
        const response = await axios.post(apiUrl, requestData, { headers })

        // Handle a successful response
        if (response.data.successful === true) {
          // Trigger holdTransactionAlertModal and set other state if needed
          commit('setHoldTransactionAlertModal', true)
          commit('setIsLoadingDetails', false)
          // Handle other success actions if needed
        } else {
          // Handle other response statuses as needed
          console.error('Failed to hold payout transaction')
          await dispatch('handleErrorResponse', response)
          commit('setDetailsErrorModal', true)
          commit('setErrorMessageresponse', response.error.message)
        }
      } catch (error) {
        commit('setErrorMessageresponse' + error.message)
        commit('setDetailsErrorModal', true)
        // Handle any errors that occur during the API request
        await dispatch('handleErrorResponse', error)
        commit('setIsLoadingDetails', false)
        commit('setDetailsErrorModal', true)
        commit('setErrorMessageresponse', error.message)
        commit('setOfflinePayment', false)
      } finally {
        // Set isLoadingDetails to false after handling the response or error
        commit('setIsLoadingDetails', false)
      }
    },

    async unHoldPayoutAPi({ commit, state, dispatch }) {
      try {
        commit('setIsLoadingDetails', true)

        const apiUrl = `${process.env.VUE_APP_API_KEY_KADA_SERVICE}/admin/transactions/payout/hold-unhold`
        const requestData = {
          action_type: 'UnHold',
          transaction_id: state.selectedDonation.transaction_id,
        }

        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        // Make the POST request using axios
        const response = await axios.post(apiUrl, requestData, { headers })

        // Handle a successful response
        if (response.data.successful === true) {
          const successMessage =
            response?.data?.message || 'Operation successful!'
          ElMessage.success(successMessage)

          store.commit('setShowModalDetailsBelow', false)
          commit('setShowPaymentMethodAvailable', true)

          // Handle success actions if needed
        } else {
          // Handle other response statuses as needed
          console.error('Failed to unhold payout transaction')
          await dispatch('handleErrorResponse', response)
          commit('setDetailsErrorModal', true)
          commit('setErrorMessageresponse', response.error.message)
        }
      } catch (error) {
        // Handle any errors that occur during the API request
        await dispatch('handleErrorResponse', error)
        commit('setDetailsErrorModal', true)
        commit('setErrorMessageresponse', error.message)
      } finally {
        // Set isLoadingDetails to false after handling the response or error
        commit('setIsLoadingDetails', false)
      }
    },
    async retryPayout({ commit, state, dispatch }) {
      try {
        commit('setIsLoading', true)

        const apiUrl = `${process.env.VUE_APP_API_KEY_KADA_SERVICE}/admin/transactions/retry-transaction`
        const requestData = {
          transaction_reference: state.selectedDonation.transaction_reference,
        }

        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        // Make the POST request using axios
        const response = await axios.post(apiUrl, requestData, { headers })

        commit('setIsLoadingDetails', false)

        // Handle a successful response
        if (response.data.successful === true) {
          commit('setIsLoading', false)
          commit('setShowPaymentMethodAvailable', true)

          const successMessage =
            response?.data?.message || 'Operation successful!'
          ElMessage.success(successMessage)

          commit('setPayoutConfig', response.data)

          const providers = response.data.result.providers
          for (const provider of providers) {
            if (provider.name === 'PayStack') {
              commit('setCurrentPayStackId', provider.id)
              commit('setShowPaymentMethodAvailable', true)
            } else if (provider.name === 'Offline') {
              commit('setCurrentOfflineId', provider.id)
              commit('setShowPaymentMethodAvailable', true)
            } else {
              commit('setShowPaymentMethodAvailable', false)
            }
          }

          // Trigger the getPayoutRequest action
          await dispatch('getPayoutRequest')
        } else {
          // Handle other response statuses as needed
          console.error('Failed to retry payout transaction')
          await dispatch('handleErrorResponse', response)
          commit('setDetailsErrorModal', true)
          commit('setErrorMessageresponse', response.error.message)
        }
      } catch (error) {
        // Handle any errors that occur during the API request
        await dispatch('handleErrorResponse', error)
        commit('setDetailsErrorModal', true)
        commit('setErrorMessageresponse', error.message)
        commit('setOfflinePayment', false)
      } finally {
        // Set isLoadingDetails to false after handling the response or error
        commit('setIsLoading', false)
      }
    },

    async finalisePaystackPayment({ commit, state, dispatch }) {
      try {
        commit('setIsLoadingDetails', true)

        const apiUrl = `${process.env.VUE_APP_API_KEY_KADA_SERVICE}/admin/transactions/payout/finalize`
        const requestData = {
          payout_id: state.paystackInitializeData.result.payout_id,
          provider_id: state.currentPayStackId,
          challenge: state.otpOneTime,
          challenge_type: 'OTP',
          // Add other required parameters here...
        }

        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        const response = await axios.post(apiUrl, requestData, { headers })

        commit('setIsLoadingDetails', false)

        if (response.data.successful === true) {
          commit('setShowErrorOneTimePayment', false)
          commit('setOneTimePaymentModal', false)
          commit('setSuccessModalShow', true)
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          // Unauthorized or forbidden status
          const errorMessage =
            error.response.data?.error?.messages[0] ||
            'Unauthorized access. Logging out...'
          ElMessage.error(errorMessage)
          dispatch('logout')
        } else {
          // Extract error message from the Axios response
          const errorMessage =
            error.response?.data?.error?.messages[0] || 'Request failed!'

          // Commit the error to the Vuex store
          commit('setErrorMessageresponse', errorMessage)

          // Display the API error message using Element UI
          ElMessage.error(errorMessage)

          commit('setDetailsErrorModal', true)
          commit('setOneTimePaymentModal', false)
        }
      } finally {
        commit('setIsLoadingDetails', false)
      }
    },

    async finaliseOfflinePayment({ commit, state, dispatch }) {
      try {
        commit('setIsLoadingDetails', true)

        const apiUrl = `${process.env.VUE_APP_API_KEY_KADA_SERVICE}/admin/transactions/payout/finalize`
        const requestData = {
          payout_id: state.oneTimePaymentInitializeData.result.payout_id,
          provider_id: state.currentOfflineId,
          challenge: state.offlineOtpValue,
          challenge_type:
            state.oneTimePaymentInitializeData.result.challenge_type,
        }

        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        const response = await axios.post(apiUrl, requestData, { headers })

        if (response.data.successful === true) {
          commit('setOfflinePayment', false)
          commit('setSuccessModalShow', true)
        } else {
          commit(
            'setErrorMessageresponse',
            response.error.message || 'Request failed!',
          )
          commit('setDetailsErrorModal', true)
          commit('setOfflinePayment', false)
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          // Unauthorized or forbidden status
          const errorMessage =
            error.response.data?.error?.messages[0] ||
            'Unauthorized access. Logging out...'
          ElMessage.error(errorMessage)
          dispatch('logout')
        } else {
          commit(
            'setErrorMessageresponse',
            error.response?.data?.message || 'An unexpected error occurred!',
          )
          commit('setDetailsErrorModal', true)
          commit('setOfflinePayment', false)
        }
      } finally {
        commit('setIsLoadingDetails', false)
      }
    },

    async initiateOfflinePayment({ commit, state, dispatch }) {
      try {
        commit('setDetailsErrorModal', false)
        commit('setIsLoadingDetails', true)

        const apiUrl = `${process.env.VUE_APP_API_KEY_KADA_SERVICE}/admin/transactions/payout/transaction/${state.selectedDonation.transaction_id}/initiate`
        const requestData = {
          provider_id: state.currentOfflineId,
        }

        // Make the POST request using axios
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        const response = await axios.post(apiUrl, requestData, { headers })

        // Handle a successful response
        if (response.data.successful === true) {
          commit('setOneTimePaymentInitializeData', response.data)

          store.commit('setOfflinePayment', true)
        }

        // else {
        //     // Use the error middleware function
        //     await dispatch('handleErrorResponse', response);
        //     commit('setErrorMessageresponse', response.error.message);
        //     commit('setDetailsErrorModal', true);
        // }
      } catch (error) {
        if (
          error &&
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          // Unauthorized or forbidden status
          const errorMessage =
            error.response.data?.error?.messages[0] ||
            'Unauthorized access. Logging out...'
          ElMessage.error(errorMessage)

          // Optionally, show a message related to logout
          dispatch('logout')
        } else {
          // Use the error handling logic directly
          // Extract error message from the Axios response
          const errorMessage =
            error.response.data?.error?.messages[0] || 'Request failed!'

          // Commit the error to the Vuex store
          commit('setErrorMessageresponse', errorMessage)
          // Display the API error message using Element UI
          ElMessage.error(errorMessage)
          commit('setDetailsErrorModal', true)
        }
      } finally {
        // Set isLoadingDetails to false after handling the response or error
        commit('setIsLoadingDetails', false)
      }
    },

    async initiatePaystackPayment({ commit, state, dispatch }) {
      commit('setIsLoadingDetails', true)

      try {
        const apiUrl = `${process.env.VUE_APP_API_KEY_KADA_SERVICE}/admin/transactions/payout/transaction/${state.selectedDonation.transaction_id}/initiate`
        const requestData = {
          provider_id: state.currentPayStackId,
        }

        // Make the POST request using axios
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        const response = await axios.post(apiUrl, requestData, { headers })

        commit('setIsLoadingDetails', false)

        // Handle a successful response
        if (response.data.successful === true) {
          commit('setPaystackInitializeData', response.data)
          store.commit('setOneTimePaymentModal', true)
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          // Unauthorized or forbidden status
          const errorMessage =
            error.response.data?.error?.messages[0] ||
            'Unauthorized access. Logging out...'
          ElMessage.error(errorMessage)

          // Optionally, show a message related to logout
          dispatch('logout')
        } else {
          // Extract error message from the Axios response
          const errorMessage =
            error.response.data?.error?.messages[0] || 'Request failed!'

          ElMessage.info('Redirecting User to Pay Offline Method')
          commit('setErrorMessageresponse', errorMessage)

          // Display the API error message using Element UI
          ElMessage.error(errorMessage)

          // Log the error details

          // Navigate to the error page

          commit('setIsLoadingDetails', false)
          commit('setDetailsErrorModal', true)

          setTimeout(function () {
            store.commit('setOneTimePaymentModal', false)
            store.commit('setOfflinePayment', true)
            store.dispatch('initiateOfflinePayment')
            commit('setDetailsErrorModal', false)
          }, 3000)
        }

        //     // Handle any errors that occur during the API request
        //     await dispatch('handleErrorResponse', error);
        //     commit('setIsLoadingDetails', false);
        //     store.commit('setOneTimePaymentModal', false);
        //     commit('setDetailsErrorModal', true);
        //
        //     commit('setErrorMessageresponse' , error.message )
        //     // Handle any errors that occur during the API request
        //
        //     store.commit('setOneTimePaymentModal' ,  false);
        //
        //
        //     setTimeout(function () {
        //         store.commit('setOneTimePaymentModal', true);
        //         store.commit('setOfflinePayment', true);
        //         store.dispatch('initiateOfflinePayment');
        //     }, 2000);
      }
    },

    async loadMoreDataPayoutRequest({ dispatch, commit, state }) {
      try {
        const newLimit = state.dataFetchLength + 20
        const apiUrl =
          process.env.VUE_APP_API_KEY_KADA_SERVICE +
          `/admin/transactions/payouts/list?size=${newLimit}&page=0`
        const requestData = {
          payout_status: state.filterBY.payoutStatus,
          request_type: state.filterBY.requestType,
          start_date: state.start_date,
          end_date: state.end_date,
        }

        const requestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${state.sessionId}`,
          },
        }

        // Make the API request
        const response = await axios.post(apiUrl, requestData, requestConfig)

        if (response.data) {
          commit('setPayoutDetailsAPI', response.data.result.transactions)
          commit('setDataFetchLength', newLimit)
        }
        // Regardless of whether the API request was successful or not, setIsLoading to false
      } catch (error) {
        await dispatch('handleErrorResponse', error)
        // console.error('Error:', error);
        // await router.push({name: 'ErrorPageView'})
        // Handle the error properly, e.g., show an error message to the user
      } finally {
        commit('setIsLoading', false)
      }
    },

    async getPayoutRequest({ commit, state, dispatch }) {
      try {
        commit('setIsLoading', true)
        // Check if the data is already available in state.payoutDetailsAPI
        // Data is not available, make the API request
        const apiUrl =
          process.env.VUE_APP_API_KEY_KADA_SERVICE +
          '/admin/transactions/payouts/list?size=10&page=0'
        const requestData = {
          payout_status: null,
          request_type: null,
          start_date: state.start_date,
          end_date: state.end_date,
        }

        const requestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
          },
        }

        const response = await axios.post(apiUrl, requestData, requestConfig)

        // Update state and handle the response
        commit('setPayoutDetailsAPI', response.data.result.transactions)
      } catch (error) {
        await dispatch('handleErrorResponse', error)
      } finally {
        commit('setIsLoading', false)
      }
    },

    async getPayoutHistoryAPI({ commit, state, dispatch }) {
      try {
        const apiUrl =
          process.env.VUE_APP_API_KEY_KADA_SERVICE +
          '/admin/transactions/payouts/history?size=15&page=0'
        const requestData = {
          payout_status: null,
          request_type: null,
          start_date: state.start_date,
          end_date: state.end_date,
        }

        const requestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
          },
        }

        const response = await axios.post(apiUrl, requestData, requestConfig)

        if (response.data) {
          commit('setIsLoading', false)
          commit('SetPayoutHistoryAPI', response.data.result.transactions)
          // Update other relevant state if needed
        } else {
          commit('setIsLoading', false)
          // Handle API error using the handleErrorResponse function
          await dispatch('handleErrorResponse', response)
        }
      } catch (error) {
        console.error('Error:', error)
        // Use the handleErrorResponse action for error handling
        await dispatch('handleErrorResponse', error)
        // Route to the error page if needed
      } finally {
        commit('setIsLoading', false)
      }
    },
    async loadMoreDataPayoutHistory({ commit, state, dispatch }) {
      try {
        const newLimit = state.dataFetchLength + 20
        const apiUrl =
          process.env.VUE_APP_API_KEY_KADA_SERVICE +
          `/admin/transactions/payouts/history?size=${newLimit}&page=0`
        const requestData = {
          payout_status: state.filterBY.payoutStatus,
          request_type: state.filterBY.requestType,
          start_date: state.start_date,
          end_date: state.end_date,
        }

        const requestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
          },
        }

        // Make the API request
        const response = await axios.post(apiUrl, requestData, requestConfig)

        if (response.data) {
          commit('setIsLoading', false)
          commit('SetPayoutHistoryAPI', response.data.result.transactions)
          commit('setDataFetchLength', newLimit)
        } else {
          commit('setIsLoading', false)
          // Handle API error using the handleErrorResponse function
          await dispatch('handleErrorResponse', response)
        }
      } catch (error) {
        await dispatch('handleErrorResponse', error)
        // Handle the error properly, e.g., show an error message to the user
      } finally {
        commit('setIsLoading', false)
      }
    },

    async loadMoreDataDonationHistory({ commit, dispatch, state }) {
      try {
        const newLimit = state.dataFetchLength + 20
        const apiUrl =
          process.env.VUE_APP_API_KEY_KADA_SERVICE +
          `/admin/transactions/donations/list?size=${newLimit}&page=0`
        const requestData = {
          status: state.filterBY.payoutStatus,
          request_type: state.filterBY.requestType,
          start_date: state.start_date,
          end_date: state.end_date,
        }

        const requestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
          },
        }

        // Make the API request
        const response = await axios.post(apiUrl, requestData, requestConfig)

        if (response.data) {
          commit('setDonationHistory', response.data.result.transactions)
          commit('setDataFetchLength', newLimit)
        } else {
          // Handle API error using the handleErrorResponse function
          await dispatch('handleErrorResponse', response)
        }
      } catch (error) {
        // Handle other errors using the handleErrorResponse function
        await dispatch('handleErrorResponse', error)
      } finally {
        commit('setIsLoading', false)
      }
    },

    async getDonationHistory({ commit, state, dispatch }) {
      try {
        const apiUrl =
          process.env.VUE_APP_API_KEY_KADA_SERVICE +
          '/admin/transactions/donations/list?size=20&page=0'
        const requestData = {
          status: null,
          request_type: null,
          start_date: state.start_date,
          end_date: state.end_date,
        }

        const requestConfig = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
          },
        }

        // Make the API request
        const response = await axios.post(apiUrl, requestData, requestConfig)

        if (response.data) {
          commit('setDonationHistory', response.data.result.transactions)
        } else {
          // Handle API error using the handleErrorResponse function
          await dispatch('handleErrorResponse', response)
        }
      } catch (error) {
        // Handle other errors using the handleErrorResponse function
        await dispatch('handleErrorResponse', error)
      } finally {
        commit('setIsLoading', false)
      }
    },
    async fetchDashboardData({ commit, state, dispatch }) {
      try {
        commit('setIsLoading', true)

        // Make a GET request to the /statistics endpoint
        const apiUrl = `${process.env.VUE_APP_API_KEY_KADA_SERVICE}/admin/statistics`
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        const response = await axios.get(apiUrl, { headers })

        if (response.data.successful === true) {
          commit('updateDashboardData', response.data.result)
        } else {
          // Handle other response statuses as needed
          await dispatch('handleErrorResponse', response)
        }
      } catch (error) {
        commit('setIsLoading', false)
        await dispatch('handleErrorResponse', error)
      } finally {
        commit('setIsLoading', false)
      }
    },

    async fetchKadaCentralUsers({ commit, state, dispatch }) {
      try {
        commit('setIsLoading', true)

        // Make a GET request to the /statistics endpoint
        const apiUrl = `${process.env.VUE_APP_API_KEY_KADA_SERVICE}/admin/identity/users`
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
        }

        const response = await axios.get(apiUrl, { headers })

        if (response.data.successful === true) {
          commit('setKadaCentralUsers', response.data.result.responseContent)
        } else {
          // Handle other response statuses as needed
          await dispatch('handleErrorResponse', response)
        }
      } catch (error) {
        commit('setIsLoading', false)
        await dispatch('handleErrorResponse', error)
      } finally {
        commit('setIsLoading', false)
      }
    },

    async getPayoutConf({ commit, state }) {
      try {
        commit('setShowPaymentMethodAvailable', false)
        commit('setIsLoading', true)

        const response = await axios.get(
          `${process.env.VUE_APP_API_KEY_KADA_SERVICE}/admin/transactions/payout/${state.selectedDonation.transaction_id}/payout-configuration`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${state.sessionId}`, // Add Authorization header
            },
          },
        )

        commit('setPayoutConfig', response.data)
        commit('setIsLoading', false)

        const providers = response.data.result.providers
        let showPaymentMethodAvailable = false

        for (const provider of providers) {
          if (provider.name === 'PayStack') {
            commit('setCurrentPayStackId', provider.id)
            showPaymentMethodAvailable = true
          } else if (provider.name === 'Offline') {
            commit('setCurrentOfflineId', provider.id)
            showPaymentMethodAvailable = true
          }
        }

        commit('setShowPaymentMethodAvailable', showPaymentMethodAvailable)
      } catch (error) {
        const errorMessage = error?.messages || 'Request failed!'

        ElMessage.error(errorMessage)
      } finally {
        commit('setIsLoading', false)
      }
    },
  },

  getters: {
    sessionId: (state) => state.sessionId,

    getHeadersData: (state) => state.headersData,
    //payoutDetailsAPI
    getPayoutDetailsAPI: (state) =>
      state.payoutDetailsAPI.result.transactions.items,
    getPayoutDetailsAPITest: (state) => state.payoutDetailsAPITest,
  },
})
export default store
