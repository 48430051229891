<template>


<headerNav  v-if="$route.meta.showHeaderAndSidebar" ></headerNav>
    <side-bar v-if="$route.meta.showHeaderAndSidebar"></side-bar>


  <router-view />

</template>

<script setup>
import HeaderNav from "@/components/HeaderNav.vue";
import SideBar from "@/components/SideBar.vue";
import {onMounted, ref} from "vue";

import { useStore } from 'vuex'

// Define a reactive variable for device data
const deviceData = ref({
  deviceId: '',
  deviceName: '',
  deviceOS: '',
  osVersion: ''
})

// Get access to the Vuex store
const store = useStore()

// Function to generate a unique device ID by concatenating information



// Fetch data when the component is mounted
onMounted(() => {

  // Get device ID and OS version
  deviceData.value.deviceId = navigator.userAgent
  deviceData.value.deviceOS = navigator.platform
  deviceData.value.osVersion = navigator.appVersion

  // Set device name (you may prompt the user for this)
  deviceData.value.deviceName = navigator.appName

  // Commit data to Vuex store
  store.commit('setDeviceData', deviceData.value)
})



</script>





<style lang="scss">

@import url('https://fonts.cdnfonts.com/css/euclid-circular-b');

@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);

@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);

@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');

@import url('https://fonts.cdnfonts.com/css/euclid-circular-b');



#app {
  font-family: "Euclid Circular B",  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  margin: 0;
  padding: 0;
  //color: #2c3e50;
}

//nav {
//  padding: 30px;
//
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}


body {
  font-family: Arial, sans-serif;
  background-color: #F0F4F7;
  margin: 0;
  padding: 0;
}

.container {
  //max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  color: #666;
  font-size: 16px;
  //line-height: 1.5;
}

a{
  cursor: grab;
}



div#radix-id-44471-8-trigger-0 {
  display: none;
}


//button.amplify-button.amplify-button--primary.amplify-field-group__control.amplify-authenticator__font {
//  background: #043C83;
//}
//
//.amplify-button--primary:hover {
//  background-color: hsl(0deg 61.44% 54.91%);
//  border-color: var(--amplify-components-button-primary-hover-border-color);
//  color: var(--amplify-components-button-primary-hover-color);
//}
//
//.amplify-tabs-item[data-state=active] {
//  color: var(--amplify-components-tabs-item-active-color);
//  border-color: var(--amplify-components-tabs-item-active-border-color);
//  background-color: var(--amplify-components-tabs-item-active-background-color);
//  transition-property: none;
//}

//.fa-2x {
//  font-size: 2em;
//}
//.fa {
//  position: relative;
//  display: table-cell;
//  height: 18px;
//  width: 18px;
//  text-align: center;
//  vertical-align: middle;
//  font-size:20px;
//  padding-left: 25px;
//
//}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: red; */
  border-radius: 10px;
  /* height: 168.5px; */
  width: 8px;
  opacity: 0.5;
  background-color: #38455E;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #b30000; */
  background-color: #38455E;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}






ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

/* Primary Button Styles */
button.btn.btn-primary.button {
  background-color: #043883;
  color: #fff;
  border: 1px solid transparent;
  padding: 10px 20px;
  cursor: pointer;
  transition: border 0.3s ease-in-out; /* Add a transition for the border */
}

button.btn.btn-primary.button:hover {
  background-color: #fff;
  color: #043883;
  border: 1px solid #043883; /* Show the border on hover */
}

button.btn.btn-secondary.button.secondary {
  background-color: #FF6060;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
}
button.btn.btn-secondary.button.secondary:hover {
  background-color: #fff;
  color: #FF6060;
  border: 1px solid #FF6060; /* Show the border on hover */
}


.el-input__wrapper {
  flex-grow: 1;
}

.el-input__inner{
  align-content:flex-start;
}
.login .el-input input {
  padding-left: 20px;
  text-align-last: start;
}

.el-input{
  background-color: white;
}

</style>
